import { Box, Card, CardHeader, Grid2 as Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

export default function EventUrl({ id, formik }) {
  const { t } = useTranslation();

  const eventUrl = `${process.env.REACT_APP_WEB_URL}/events/${formik.values.slug}`;
  const attendeeUrl = `${window.location.origin}/event/attend/${id}`;

  return (
    <Card variant="outlined">
      <CardHeader title={t("event.url")} />

      <Grid
        container
        spacing={2}
        sx={{
          p: 2,
        }}
      >
        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" textAlign={"center"} pb={1}>
              {t("event.event_details")}
            </Typography>

            <QRCode value={eventUrl} size={128} />

            <Link href={eventUrl} target="_blank">
              {t("event.open_link")}
            </Link>
          </Box>
        </Grid>

        <Grid
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" textAlign={"center"} pb={1}>
              {t("event.attend")}
            </Typography>

            <QRCode value={attendeeUrl} size={128} />

            <Link href={attendeeUrl} target="_blank">
              {t("event.open_link")}
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
