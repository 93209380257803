import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { BarcodeScanner } from "react-barcode-scanner";
import "react-barcode-scanner/polyfill";
// components
import FullScreenLoading from "../../components/FullScreenLoading";
import { EventApi } from "../../api/EventApi";
import AlertHelper from "../../helper/AlertHelper";

export default function Attend() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [facingMode, setFacingMode] = useState("environment");
  const delay = 2000;

  useEffect(() => {
    if (!id) {
      return;
    }

    fetchEventDetail();
  }, [id]);

  const fetchEventDetail = async () => {
    try {
      setIsLoading(true);

      const response = await EventApi.details(id);

      console.log(response);

      setEvent(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScan = result => {
    if (isLoading) {
      return;
    }

    const code = result.rawValue;

    submit(code);
  };

  const submit = async code => {
    try {
      setIsLoading(true);
      const success = await EventApi.attendEvent(id, code);

      if (success) {
        AlertHelper.fire({
          title: "登記成功",
          icon: "success",
          timer: delay,
        });
      }
    } catch (error) {
      console.error(error);
      AlertHelper.fire({
        title: "Error",
        icon: "error",
        text: error,
        timer: delay,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#000",
      }}
    >
      <FullScreenLoading open={isLoading} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          p: 2,
          bgcolor: "rgba(0, 0, 0, 0.5)",
          color: "white",
        }}
      >
        <Typography color="white">{event?.title}</Typography>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component={"img"}
          sx={{
            width: 250,
            height: 250,
          }}
          src="/assets/images/ios-qr-scanner.png"
        />
      </Box>

      {!isLoading && (
        <BarcodeScanner
          trackConstraints={{
            facingMode: facingMode,
          }}
          options={{
            delay: delay,
            formats: ["qr_code"],
          }}
          onCapture={result => {
            handleScan(result);
          }}
          onError={error => {
            console.error(error);
          }}
        />
      )}
    </Box>
  );
}
