import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReportExportButton from "../../../components/ReportExportButton";

// components
import DebugLogger from "../../../components/DebugLogger";
import TableSkeleton from "../../../components/TableSkeleton";

// api
import { toast } from "react-toastify";
import { EventApi } from "../../../api/EventApi";

export default function List({ eventId, haveCreatePermission, haveUpdatePermission }) {
  const { t } = useTranslation();
  const csvRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(-1);
  const [filter, setFilter] = useState([{ field: "event_id", value: eventId, op: "eq" }]);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [colSpan, setColSpan] = useState(6);
  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState([
    {
      label: t("event.guest_id"),
      key: "id",
    },
    {
      label: t("event.guest_name"),
      key: "name",
    },
    {
      label: t("event.guest_phone_number"),
      key: "phoneNumber",
    },
    {
      label: t("event.guest_identity"),
      key: "identity",
    },
    {
      label: t("layout.status"),
      key: "status",
    },
    {
      label: t("layout.updated_at"),
      key: "updatedAt",
    },
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    let csvData = [];

    data.map((row, index) => {
      csvData.push({
        id: row.id,
        name: row.name,
        phoneNumber: row.phoneNumber,
        identity: t(`event.guest_identity_${row.identity}`),
        status: t(`event.guest_status_${row.status}`),
        updatedAt: row.updatedAt,
      });
    });

    setCsvData(csvData);
  }, [data]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await EventApi.guestList(page, pageSize, filter);
      if (response) {
        setData(response.data);
        setTotal(response.total);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const viewDetail = id => {
    navigate(`/event/guest/${id}`);
  };

  const addGuest = () => {
    navigate(`/event/guest/create/${eventId}`);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 8,
      }}
    >
      <CardHeader
        title={t("event.guest")}
        action={
          haveCreatePermission && (
            <ReportExportButton
              headers={headers}
              csvRef={csvRef}
              filename={`${data[0]?.event?.title} Guest List.csv`}
              csvData={csvData}
            />
            //     <Button
            //       color="inherit"
            //       variant="outlined"
            //       startIcon={<AddIcon />}
            //       onClick={addGuest}
            //     >
            //       <Translation>{t => t("event.new_guest")}</Translation>
            //     </Button>
          )
        }
      />

      <CardContent>
        <DebugLogger title="Event Guest" data={data} hidden={true}></DebugLogger>
        <TableContainer elevation={0} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width={"5%"}>{t("event.guest_id")}</TableCell>
                <TableCell width={"10%"}>{t("event.guest_name")}</TableCell>
                <TableCell width={"10%"}>{t("event.guest_phone_number")}</TableCell>
                <TableCell width={"10%"}>{t("event.guest_identity")}</TableCell>
                <TableCell width={"10%"}>{t("layout.status")}</TableCell>
                <TableCell width={"10%"}>{t("layout.updated_at")}</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableSkeleton row={pageSize == -1 ? 10 : pageSize} colSpan={colSpan} />
            ) : (
              <TableBody>
                {!data.length && (
                  <TableRow>
                    <TableCell colSpan={colSpan} align="center">
                      {t("layout.no_data")}
                    </TableCell>
                  </TableRow>
                )}
                {data.map((row, index) => (
                  <TableRow
                    hover
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() =>
                      haveUpdatePermission
                        ? viewDetail(row.id)
                        : toast.error(t("error.no_update_permission"))
                    }
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.phoneNumber}</TableCell>
                    <TableCell>{t(`event.guest_identity_${row.identity}`)}</TableCell>
                    <TableCell>{t(`event.guest_status_${row.status}`)}</TableCell>
                    <TableCell>{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
