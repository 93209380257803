import AuthProvider from "../components/AuthProvider";
import { getEventPageName } from "../helper/GlobalValueHelper";
import EventAttend from "../views/event/Attend";

const AttendRoute = {
  children: [
    {
      path: "/event/attend/:id",
      element: (
        <AuthProvider page={getEventPageName()} action={"view"}>
          <EventAttend />
        </AuthProvider>
      ),
    },
  ],
};

export default AttendRoute;
